export default daffy.ui.form.Tab = Class.extend({
	init: function(layout, df) {
		this.layout = layout;
		this.df = df || {};
		this.label = this.df && this.df.label || 'Details';
		this.fields_list = [];
		this.fields_dict = {};
		this.make();
		this.refresh();
	},

	make: function() {
		if (!this.layout.page) {
			this.layout.page = $('<div class="form-page"></div>').appendTo(this.layout.wrapper);
		}

		const id = `${daffy.scrub(this.layout.doctype, '-')}-${this.df.fieldname}`;
		this.parent = $(`<li class="nav-item">
			<a class="nav-link ${this.df.active ? "active": ""}" id="${id}-tab"
				data-toggle="tab" href="#${id}" role="tab"
				aria-controls="home" aria-selected="true">
					${__(this.label)}
			</a>
		</li>`).appendTo(this.layout.tabs_list);

		this.wrapper = $(`<div class="tab-pane fade ${this.df.active ? "active": ""}"
			id="${id}" aria-labelledby="${id}-tab">
		`).appendTo(this.layout.tabs_content);

		this.layout.tabs.push(this);
	},

	set_content: function() {
	},

	refresh: function() {
		if (!this.df) return;

		// hide if explicitly hidden
		let hide = this.df.hidden || this.df.hidden_due_to_dependency;
		if (!hide && this.layout && this.layout.frm && !this.layout.frm.get_perm(this.df.permlevel || 0, "read")) {
			hide = true;
		}
		hide && this.toggle(false);
	},

	toggle: function(show) {
	 	this.parent.toggleClass('hide', !show);
		this.wrapper.toggleClass('hide', !show);
		this.parent.toggleClass('show', show);
		this.wrapper.toggleClass('show', show);
		this.hidden = !show;
	},

	show: function() {
		this.parent.show();
	},

	hide: function() {
		this.parent.hide();
	},

	set_active: function() {
		this.parent.find('.nav-link').tab('show');
		this.wrapper.addClass('show');
	},

	is_active: function() {
		return this.wrapper.hasClass('active');
	},

	is_hidden: function() {
		this.wrapper.hasClass('hide')
			&& this.parent.hasClass('hide');
	}
})