//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'TreeNode',
	props: ['node', 'selected_node'],
	components: {
		TreeNode: () => daffy.ui.components.TreeNode
	}
}
